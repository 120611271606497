import Checkbox = require("Everlaw/UI/Checkbox");
import Dom = require("Everlaw/Dom");
import Widget = require("Everlaw/UI/Widget");
import { makeFocusable } from "Everlaw/UI/FocusDiv";
import * as Input from "Everlaw/Input";

/**
 * A tristate checkbox.  The states it toggles through are:
 * UNCHECKED: checked = false, indeterminate = false
 * CHECKED: checked = true, indeterminate = false
 * NOT: checked = false, indeterminates = true
 */
class TripleCheckbox extends Widget {
    state: TripleCheckbox.CheckboxState;
    readonly checkbox: Checkbox;
    private onChange: (
        oldState: TripleCheckbox.CheckboxState,
        newState: TripleCheckbox.CheckboxState,
    ) => void = (ns) => {};
    constructor(
        params: Checkbox.Params,
        onChange: (
            oldState: TripleCheckbox.CheckboxState,
            newState: TripleCheckbox.CheckboxState,
        ) => void,
        toggleable = false,
        private toggleThreeStates = true,
    ) {
        super();
        params.onChange = () => {
            // Undo the default checkbox behavior.
            toggleable && this.toggle();
            this.updateDisplayState();
        };
        params.makeFocusable = false;
        this.checkbox = new Checkbox(params);
        this.node = Dom.node(this.checkbox);
        Dom.addClass(this.checkbox, "label-row-checkbox");
        this.state = TripleCheckbox.CheckboxState.UNCHECKED;
        this.onChange = onChange;

        // The default keyboard handlers for checkbox don't interact well with TripleCheckbox, so
        // we create our own focus div here.
        const focusDiv = makeFocusable(this.checkbox.labelNode, "focus-label-style");
        this.registerDestroyable([
            this.checkbox,
            focusDiv,
            Input.fireCallbackOnKey(focusDiv.node, [Input.SPACE], (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.toggle();
            }),
        ]);
    }
    clear() {
        this.setState(TripleCheckbox.CheckboxState.UNCHECKED, true);
    }
    setDisabled(disabled: boolean) {
        this.checkbox.setDisabled(disabled);
    }
    setState(state: TripleCheckbox.CheckboxState, silent?: boolean) {
        const oldState = this.state;
        this.state = state;
        !silent && this.onChange(oldState, state);
        this.updateDisplayState();
    }
    toggle() {
        const oldState = this.state;
        if (this.toggleThreeStates) {
            this.state = (this.state.valueOf() + 1) % 3;
        } else {
            // INDETERMINATE and CHECKED should toggle to UNCHECKED
            this.state =
                this.state !== TripleCheckbox.CheckboxState.UNCHECKED
                    ? TripleCheckbox.CheckboxState.UNCHECKED
                    : TripleCheckbox.CheckboxState.CHECKED;
        }
        this.updateDisplayState();
        this.onChange && this.onChange(oldState, this.state);
    }
    private updateDisplayState() {
        this.checkbox.set(this.state === TripleCheckbox.CheckboxState.CHECKED, true);
        this.checkbox.setIndeterminate(this.state === TripleCheckbox.CheckboxState.INDETERMINATE);
    }
    setAriaLabel(label: string): void {
        this.checkbox.setAriaLabel(label);
    }
    setAriaLive(liveValue: string): void {
        this.checkbox.setAriaLive(liveValue);
    }
}

module TripleCheckbox {
    export enum CheckboxState {
        UNCHECKED,
        CHECKED,
        INDETERMINATE,
    }
}

export = TripleCheckbox;
